import React, { Fragment, useEffect } from "react";
import Header from '../components/header';
import Footer from '../components/footer';
import { matchRoutess } from "react-router-dom";

const WebsiteLayout = (props) => {
	// const routes = matchRoutess('/membership');
	// console.log(routes,"Routes from websitelayout");
	// useEffect(() => {
	// 	const existingRoute = routes.find(route => route.pathname === location.pathname); // all routes that need layout
	// 	setShowHeader(!!existingtRoute);
	//   }, [location]);
	
	return (
		<Fragment>
			<Header admin={props.admin} logOutPopUp={props.logOutPopUp} setLogOutPopUp={props.setLogOutPopUp}/>
			<main className={`website-container ${props.className}`}>
				{props.children}
			</main>
			<Footer/>
		</Fragment>
		
	)
}

export default WebsiteLayout;